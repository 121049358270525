import { createContext, useState, useEffect } from "react";

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [openuserProfile, setopenuserProfile] = useState(false);
  const [selectedNav, setselectedNav] = useState("Masters");
  const [selectedurl, setselectedurl] = useState("");
  const [appSelectList, setappSelectList] = useState([]);
  const [selectedapp, setselectedapp] = useState({});
  const [openpairs, setopenpairs] = useState(false);
  const [openrouting, setopenrouting] = useState("");
  const [selectedPair, setselectedPair] = useState({});
  const [coin, setcoin] = useState({});
  const [pairsList, setpairsList] = useState([]);
  const [check, setcheck] = useState("off");
  const [selectVaultApp, setselectVaultApp] = useState(false);
  const [addVault, setaddVault] = useState({});
  const [selectedVaultCoin, setSelectedVaultCoin] = useState({});
  const [allpairs, setallpairs] = useState([]);
  const [useremail, setuseremail] = useState([]);
  const [topbarPairCoin, settopbarPairCoin] = useState([]);
  const [PersonalValutSet, setPersonalValutSet] = useState([]);
  const [vaultsetName, setvaultsetName] = useState("")
  const [valutInputName, setvalutInputName] = useState("Personal Vault Sets");
  const [infoFund, setinfoFund] = useState({})
  const [infoNonFund, setinfoNonFund] = useState({})
  //
  const [FundVaultsetDetail, setFundVaultsetDetail] = useState([]);
  const [vaultTab, setvaultTab] = useState("Personal Vault Sets");
  const [storeMainVault, setstoreMainVault] = useState([]);
  const [NameTabvault, setNameTabvault] = useState("Personal Vault Sets");
  const [balanceVault, setbalanceVault] = useState([])
  const [NonVaultBalance, setNonVaultBalance] = useState([])
  const [checkedName, setcheckedName] = useState("Main")
  const [CoinListed, setCoinListed] = useState("BTC/USDT");
  const [buyValue, setbuyValue] = useState("")
  const [sellValue, setsellValue] = useState("")
  const [PersonalPath, setPersonalPath] = useState("")
  const [chooseToggle, setchooseToggle] = useState("Main")
  const [mobileButton, setmobileButton] = useState('')
  const [navbarselected, setnavbarselected] = useState({
    nickName: "",
    valusetname: "",
  })
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("user"));
    setuseremail(userData);
  }, []);
  console.log("vaultsetName", checkedName, chooseToggle)
  const value = {
    setinfoFund, infoFund, setinfoNonFund, infoNonFund,
    setsellValue, sellValue, setPersonalPath, PersonalPath,
    setbuyValue, buyValue,
    setCoinListed, CoinListed, setchooseToggle, chooseToggle,
    setcheckedName, checkedName,
    setvalutInputName,setmobileButton,mobileButton,
    NonVaultBalance, setNonVaultBalance,
    setNameTabvault, NameTabvault,
    balanceVault,
    setbalanceVault,
    valutInputName,
    setstoreMainVault,
    storeMainVault,
    vaultTab,
    setvaultTab,
    PersonalValutSet,
    setPersonalValutSet,
    FundVaultsetDetail,
    setFundVaultsetDetail,
    setnavbarselected,
    navbarselected,
    setuseremail,
    useremail,
    settopbarPairCoin,
    topbarPairCoin,
    openuserProfile,
    setopenuserProfile,
    selectedNav,
    setselectedNav,
    selectedurl,
    setselectedurl,
    appSelectList,
    setappSelectList,
    selectedapp,
    setselectedapp,
    openpairs,
    setopenpairs,
    openrouting,
    setopenrouting,
    selectedPair,
    setselectedPair,
    coin,
    setcoin,
    pairsList,
    setpairsList,
    check,
    setcheck,
    selectVaultApp,
    setselectVaultApp,
    addVault,
    setaddVault,
    selectedVaultCoin,
    setSelectedVaultCoin,
    allpairs,
    setallpairs, vaultsetName, setvaultsetName
  };
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};

export default MainContextProvider;

export const MainContextConsumer = MainContext.Consumer;

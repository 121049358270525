import React, { useContext, useEffect, useState, useCallback } from "react";
import searchicon from "../../assets/images/navbar/searchicon.svg";
import searchicon1 from "../../assets/images/navbar/searchicon1.svg";
import bitcoin from "../../assets/images/terminals/bitcoin.svg";
import fiat from "../../assets/images/terminals/fiat.svg";
import bonds from "../../assets/images/terminals/bonds.svg";
import sharetoken from "../../assets/images/terminals/sharetoken.svg";
import funds from "../../assets/images/terminals/funds.svg";
import binance from "../../assets/images/terminals/binance.svg";
import vaults from "../../assets/images/navbar/vaults.svg";
import vaults1 from "../../assets/images/navbar/vaults1.svg";
import Toggle from "./ValutTogggle";
import AddVault from "../../components/addVault/addVault";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import downarrow from "../../assets/images/downarrow.svg";
import { ToastContainer, toast } from "react-toastify";
import { MainContext } from "../../components/Context/context";
import {
  GetVaultHeaxer,
  GetVaultBalancePerson,
  GetVaultNonMainValut,
  FundVaultset,
  GetAppsVaultBalace,
} from "../../services/actionservice";
export default function VaultDrawer() {
  const {
    vaultTab, setchooseToggle,
    setvaultTab,
    setNameTabvault,
    NameTabvault,
    PersonalValutSet,
    FundVaultsetDetail,
    setFundVaultsetDetail,
    setvaultsetName,
    setstoreMainVault,
    storeMainVault,
    setvalutInputName,
    valutInputName,
    setPersonalValutSet,
    setbalanceVault,
    setselectedNav,
    setopenpairs,
    setbuyValue,
    setPersonalPath,
    balanceVault, NonVaultBalance, setNonVaultBalance, setcheckedName, checkedName, setCoinListed, CoinListed,
  } = useContext(MainContext);
  const [loading, setloading] = useState(false)




  // useEffect(() => {
  //   let isMounted = true;

  //   if (isMounted) {
  //     //handleLoading()
  //     alert("whycall")
  //   };

  //   // fetchData();

  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);
  useEffect(() => {
   // handleLoading();
  }, [])
  const handleLoading = useCallback(() => {
    let obj = JSON.parse(localStorage.getItem("user"))?.["user"]?.email;
    if (!obj) return; // Prevent errors if localStorage is empty or malformed
    // alert("whycall")
    setvaultTab("Personal Vault Sets");
    setNameTabvault("Personal Vault Sets");
    setvalutInputName("Personal Vault Sets");
    GetVaultHeaxerFuntion(obj);
  }, []);

  // const handleLoading = () => {
  //   let obj = JSON.parse(localStorage.getItem("user"))["user"].email;
  //   setvaultTab('Personal Vault Sets')
  //   setNameTabvault('Personal Vault Sets')
  //   setvalutInputName('Personal Vault Sets')
  //   GetVaultHeaxerFuntion(obj);
  // }

  //   useEffect(() => {
  //     valutBalanceFunction("Main")
  // console.log("czxc",checkedName)
  //     // setcheckedName("Main")
  //   }, [CoinListed]);



  const NonmainVaultHandle = (e) => {
    setvaultTab("FundSetOne")
    setNonVaultBalance([]);
    // setMainVault(e);
    setvalutInputName(e?.nick_name);
    // alert("nonnvalue")

    let obj = {
      email: JSON.parse(localStorage.getItem("user"))["user"].email,
      path: e?.vault_set,
      minimal: true,
    };

    GetVaultNonMainValut(obj).then((response) => {
      let result = response?.data?.wallet;
      setNonVaultBalance(result);
      //   setstoreDefaultVault(result);
      console.log("111111111111", result);
    });

  };



  const valutBalanceFunction = (e) => {
    setvaultTab(e);
    let obj = JSON.parse(localStorage.getItem("user"))["user"].email;

    GetAppsVaultBalace(obj).then((response) => {
      let result = response?.data?.wallet;
      console.log("1232323432", result);
      let coin = CoinListed?.split("/");
      //   let filterQuote=result?.filter(item=>{
      //     return item?.ter_coin?.coin_code?.toUpperCase()===coin[1]?.toUpperCase()
      //   })
      //   setbuyValue(filterQuote?.[0]?.balance)
      //   console.log("filter",filterQuote?.[0]?.balance)
      setstoreMainVault(result);
    });
  };

  const BalanceValue = (e) => {
    // alert(e?.vaultset)

    setvaultTab("balaceVault");
    setPersonalPath(e?.vaultset)
    let obj = {
      email: JSON.parse(localStorage.getItem("user"))["user"].email,
      set: e?.vaultset,
    };
    setvalutInputName(`Vaults Personal ${e?.nickName}`);
    console.log("12314234", obj);
    GetVaultBalancePerson(obj).then((response) => {
      let result = response?.data?.wallet;
      setbalanceVault(result);
    });
  };
  console.log("28489272834u234", PersonalValutSet);
  const GetVaultHeaxerFuntion = (obj) => {
    // alert("callpersonal")
    setloading(true)
    GetVaultHeaxer(obj).then((response) => {
      let result = response?.data?.vaultsets;
      setPersonalValutSet(result);
      //   setstoreDefaultVault(result);
      setloading(false)

    });
  };

  const FundVaultsetFuction = (obj) => {
    // alert("callfound")
    FundVaultset(obj).then((response) => {
      let result = response?.data;
      //   setPersonalValutSet(result);
      //   setstoreDefaultVault(result);

      setFundVaultsetDetail(result);
      // setcheckedName("")
      console.log("222222222", result);
    });
  };

  const PersonalInnerTable = (e) => {
    // alert("kamal")
    // 
    let obj = JSON.parse(localStorage.getItem("user"))["user"].email;
    setvalutInputName(e);
    if (e == "Fund Vault Sets") {
     // FundVaultsetFuction(obj);
    } else {
      // 
      // setchooseToggle("")
    }
    setNameTabvault(e);
    setvaultTab(e);
    // setchooseToggle("")

  };

  const valutpage = () => {
    switch (vaultTab) {
      case "FundSetOne":
        return (
          <>
            <div className="valutPageDrawer">
              {NonVaultBalance?.length > 0 ? (
                <>
                  <div className="innerDrawervault">
                    {NonVaultBalance?.map((item) => {
                      return (
                        <div
                          className={
                            JSON.parse(localStorage.getItem("light"))
                              ? "each-coin-data"
                              : "each-coin-data-dark"
                          }
                          style={{
                            borderColor: JSON.parse(
                              localStorage.getItem("light")
                            )
                              ? ""
                              : "#5F6163",
                          }}
                        >
                          <div className="each-vault-icon-part">
                            <div>
                              <p className="each-vault-coin-name">
                                {item?.ter_coin?.coin_name}
                              </p>
                            </div>
                          </div>
                          <div className="coins-values-box">
                            <p className="each-vault-coin-value">
                              {Number(item?.balance)?.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="backValut"
                    onClick={() => setselectedNav("Masters")}
                  >Close</div>
                  <div
                    className="closeValut"
                    onClick={() => {
                      setvaultTab("Fund Vault Sets");
                      setvalutInputName("Fund Vault Sets");
                    }}
                  >
                    Back
                  </div>
                </>
              ) : (
                <>
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                </>
              )}
            </div>
          </>
        );


      case "balaceVault":
        return (
          <>
            <div className="valutPageDrawer">
              {balanceVault?.length > 0 ? (
                <>
                  <div className="innerDrawervault">
                    {balanceVault?.map((item) => {
                      return (
                        <div
                          className={
                            JSON.parse(localStorage.getItem("light"))
                              ? "each-coin-data"
                              : "each-coin-data-dark"
                          }
                          style={{
                            borderColor: JSON.parse(
                              localStorage.getItem("light")
                            )
                              ? ""
                              : "#5F6163",
                          }}
                        >
                          <div className="each-vault-icon-part">
                            <div>
                              <p className="each-vault-coin-name">
                                {item?.coinName}
                              </p>
                            </div>
                          </div>
                          <div className="coins-values-box">
                            <p className="each-vault-coin-value">
                              {Number(item?.balance)?.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="backValut"
                    onClick={() => setselectedNav("Masters")}
                  >Close</div>
                  <div
                    className="closeValut"
                    onClick={() => {
                      setvaultTab("Personal Vault Sets");
                      setvalutInputName("Vaults Personal Vault");
                    }}
                  >
                    Back
                  </div>
                </>
              ) : (
                <>
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                </>
              )}
            </div>
          </>
        );

      case "Main":
        return (
          <>
            <div className="valutPageDrawer">
              {storeMainVault?.length > 0 ? (
                <>
                  <div className="innerDrawervault">
                    {storeMainVault?.map((item) => {
                      return (
                        <div
                          className={
                            JSON.parse(localStorage.getItem("light"))
                              ? "each-coin-data"
                              : "each-coin-data-dark"
                          }
                          style={{
                            borderColor: JSON.parse(
                              localStorage.getItem("light")
                            )
                              ? ""
                              : "#5F6163",
                          }}
                        >
                          <div className="each-vault-icon-part">
                            <div>
                              <p className="each-vault-coin-name">
                                {item?.ter_coin?.coin_name}
                              </p>
                            </div>
                          </div>
                          <div className="coins-values-box">
                            <p className="each-vault-coin-value">
                              {Number(item?.balance)?.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="backValut"

                    onClick={() => setselectedNav("Masters")}
                  >Close</div>
                  <div
                    className="closeValut"
                    onClick={() => {
                      setvaultTab("Personal Vault Sets");
                      setvalutInputName("Vaults Personal Vault");
                    }}
                  >
                    Back
                  </div>
                </>
              ) : (
                <>
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                </>
              )}
            </div>
          </>
        );

      case "Fund Vault Sets":
        return (
          <>
            <>
              {FundVaultsetDetail?.length > 0 ? (
                <>
                  {FundVaultsetDetail?.map((item) => {
                    return (
                      <div
                        className={
                          JSON.parse(localStorage.getItem("light"))
                            ? "each-coin-data"
                            : "each-coin-data-dark"
                        }
                        style={{
                          borderColor: JSON.parse(localStorage.getItem("light"))
                            ? ""
                            : "#5F6163",
                        }}

                      >
                        <div className="each-vault-icon-part"
                          onClick={() => NonmainVaultHandle(item)}
                        >
                          <div>
                            <p className="each-vault-coin-name">
                              {item?.nick_name}
                            </p>
                          </div>
                        </div>

                        <div
                          className="toggle-part"
                          style={{
                            position: "initial",
                          }}
                        >
                          <Toggle id={item?.vault_set} dataInfo={item} name={item?.vault_set} />
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                </>
              )}
            </>
          </>
        );

        break;

      case "Personal Vault Sets":
        return (
          <>
            <>
              <div
                className={
                  JSON.parse(localStorage.getItem("light"))
                    ? "each-coin-data"
                    : "each-coin-data-dark"
                }

                style={{
                  borderColor: JSON.parse(localStorage.getItem("light"))
                    ? ""
                    : "#5F6163",
                }}
              >
                <div className="each-vault-icon-part" onClick={() => valutBalanceFunction("Main")}>
                  <div>
                    <p className="each-vault-coin-name">Main</p>
                  </div>
                </div>

                <div
                  className="toggle-part"
                  style={{
                    position: "initial",
                  }}
                >
                  <Toggle id="Main" name="Main" />
                </div>
              </div>
              {!loading ? (
                <>
                  {PersonalValutSet?.map((item) => {
                    return (
                      <div
                        className={
                          JSON.parse(localStorage.getItem("light"))
                            ? "each-coin-data"
                            : "each-coin-data-dark"
                        }

                        style={{
                          borderColor: JSON.parse(localStorage.getItem("light"))
                            ? ""
                            : "#5F6163",
                        }}
                      >
                        <div className="each-vault-icon-part" onClick={() => BalanceValue(item)}>
                          <div>
                            <p className="each-vault-coin-name">
                              {item?.nickName}
                            </p>
                          </div>
                        </div>

                        <div
                          className="toggle-part"
                          style={{
                            position: "initial",
                          }}
                        >
                          <Toggle id={item?.vaultset} dataInfo={item} name={item?._id} />
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    className="selektonloader"
                    style={{
                      marginBottom: "1rem",
                      height: "42px",
                      background: " #80808061",
                      width: "100%",
                    }}
                  />
                </>
              )}
            </>
          </>
        );

        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="pairs-box-new">
        <div className="each-new-coin">
          <p
            className="pairs-coin-text"
            //   onClick={() => NonmainVaultHandle("Main Vaut", "")}
            style={{
              whiteSpace: "nowrap",
              marginRight: "1.5rem",
              justifyContent: "center",
              display: "flex",
            }}
            onClick={() => PersonalInnerTable("Personal Vault Sets")}
          >
            {NameTabvault === "Personal Vault Sets" ? (
              <div
                className="borderPair"
                style={{
                  width: "50%",

                  bottom: " -5px",
                }}
              ></div>
            ) : (
              ""
            )}
            Personal Vault Sets
          </p>
          <p
            className="pairs-coin-text"
            onClick={() => PersonalInnerTable("Fund Vault Sets")}
            //   onClick={() => NonmainVaultHandle("Main Vaut", "")}
            style={{
              whiteSpace: "nowrap",
              // marginRight: "1.5rem",
              justifyContent: "center",
              display: "flex",
            }}
          // onClick={() => GetPairApiFuntion(item.quote)}
          >
            {NameTabvault === "Fund Vault Sets" ? (
              <div
                className="borderPair"
                style={{ width: "50%", bottom: " -5px" }}
              ></div>
            ) : (
              ""
            )}
            Fund Vault Sets
          </p>
        </div>

        <div
          class="nav-search-menu"
          style={{
            background: "#28313D",
            borderRadius: "5px",
            border: "none",
            width: "100%",
            marginTop: "1.3rem",
            position: "relative",
            /* padding: 0; */
            height: "3rem",
            padding: "0px 15px",
          }}
        >
          <div class="nav-search-box">
            <img class="search-nav-img" src={searchicon} alt="" />
            <input
              className="inputpairSearch"
              placeholder={`Search ${valutInputName}`}
            // value={changePairName}
            // onChange={(e) => setchangePairName(e.target.value)}
            />
          </div>

        </div>
        <div className="vaultSection">
          <div className="basecurrency">Vault</div>
          {/* <div className="basecurrency">Balance</div> */}
        </div>
        {valutpage()}
      </div>
    </>
  );
}

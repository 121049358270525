import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from "../../components/Context/context";
import './VaultToggle.scss';

function Toggle({ id, dataInfo, name }) {
  const { check, setcheck, checkedName, setcheckedName, setchooseToggle, chooseToggle, NameTabvault, setvaultsetName, setinfoFund, vaultTab, setnavbarselected, setinfoNonFund } = useContext(MainContext)
  console.log("vault_set", name)
  const toggle = (e) => {

    setcheckedName(e)
    switch (check) {
      case "off":
        setcheck('on')
        break;
      case "on":
        setcheck('off')
        break;
    }
  }
  const handleChange = (e, g, name) => {
    setcheckedName(e)
    setchooseToggle(name)

    console.log("dataInfo", g)
    // alert("innderdata")
    // if(NameTabvault == "Main")
    // {
    //   setvaultsetName("")
    // }
    // else{
    //   setvaultsetName(g?.vaultset)
    // }

    let objdata = {
      nickName: NameTabvault == "Personal Vault Sets" ? g?.nickName : NameTabvault == "Fund Vault Sets" ? g?.nick_name : "",
      valusetname: NameTabvault == "Personal Vault Sets" ? g?.vaultset : NameTabvault == "Fund Vault Sets" ? g?.vault_set : "",
    }
    setnavbarselected(objdata)
    console.log("objdata", objdata)
    if (NameTabvault == "Personal Vault Sets" &&
      e !== "Main") {
      // alert()
      setinfoNonFund(g)
      setvaultsetName(g?.vaultset)
    }
    else if (NameTabvault == "Fund Vault Sets" && e !== "Main") {
      setinfoFund(g)
      setvaultsetName(g?.vault_set)
    }
    // const item = e.target.name;

    // const isChecked = e.target.checked;
    // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  }

  console.log("checkedName", checkedName)
  // vaultTab == "Personal Vault Sets" 
  useEffect(() => {

  }, [])

  return (
    <div className='togglecomp'>
      <div className='toggle-icon-part'>
        <input className='tgl1' type="checkbox"
          defaultChecked={name == chooseToggle && true}
          checked={chooseToggle == name && true}
          onClick={() => handleChange(id, dataInfo, name)}
          id={name}
        />
        <label className="tgl2" for={name}></label>

      </div>
      {/* <p style={{color: JSON.parse(localStorage.getItem('light'))? "#5F6163" : '#E7E7E7', fontSize:'8px', textAlign:'center', marginTop:'3px'}}>{check === 'off' ? "With Balances" : "All Vaults"}</p> */}
    </div>
  )
}

export default Toggle;
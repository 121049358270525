import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import google from "../../assets/images/google.svg";
import air from "../../assets/images/air.svg";
import img1 from "../../assets/images/img1.svg";
import img2 from "../../assets/images/img2.svg";
import img3 from "../../assets/images/img3.svg";
import img4 from "../../assets/images/img4.svg";
import img5 from "../../assets/images/img5.svg";
import mainlogo from "../../assets/images/mainlogo.svg";

import funds from "../../assets/images/logointermaillogo.svg";
import "./loginPage.scss";
import { GetAppData, Loginservice } from "../../services/loginservice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { GetAllPairs, GetpairsOfQuote } from "../../services/actionservice";
import { MainContext } from "../../components/Context/context";




function Loginpage() {
  const {
    selectedurl,
    setselectedurl,
    appSelectList,
    setappSelectList,
    selectedPair,
    setselectedPair,
    coin,
    setcoin,
    pairsList,
    setpairsList,
    allpairs,
    setallpairs,
    setuseremail,
  } = useContext(MainContext);
  let navigate = useNavigate();
  let location = useLocation();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [menuselected, setmenuselected] = useState({});
  const [eye, seteye] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [firstapp, setfirstapp] = useState({});
  const [iconMenu, setmenuselectedMenu] = useState([]);

  useEffect(() => {
    localStorage.removeItem("appList");
    localStorage.removeItem("selectedappcode");
    localStorage.removeItem("pairurl");
    localStorage.removeItem("userdetails");
    localStorage.removeItem("selectedCoin");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("check");
    localStorage.removeItem("user");
    localStorage.removeItem("userapp");
    localStorage.removeItem("selectedappcode");
    localStorage.setItem("light", true);
  }, []);

  //   useEffect(() => {
  //     GetAppData(location.pathname.substring(1)).then((response) => {
  //       let result = response.data;
  //       if (result.terminals.length > 0) {
  //         console.log("xvxcvxcv", result.terminals);
  //         setfirstapp(result.terminals[0]);
  //         localStorage.setItem("userapp", JSON.stringify(result.terminals[0]));
  //         localStorage.setItem("selectedappcode", location.pathname.substring(1));
  //       } else {
  //          navigate('/instacrypto')
  //       }
  //     });
  //   }, [location.pathname]);

  useEffect(() => {
    setfirstapp({
      color_icon: funds,
      color_code_1: funds,
      color_logo: funds,
      website_title: "",
      terminal_name: "Fund",
    });
  }, []);
  console.log("setfirstapp", firstapp);

  useEffect(() => {
    setmenuselectedMenu([
      {
        id: 0,
        icon: Object.keys(firstapp).length > 0 ? firstapp.color_icon : "",
        color: Object.keys(firstapp).length > 0 ? firstapp.color_code_1 : "",
        title: Object.keys(firstapp).length > 0 ? firstapp.terminal_name : "",
        app_image: Object.keys(firstapp).length > 0 ? firstapp.color_logo : "",
      },
      {
        id: 1,
        icon: img1,
        color: "#292929",
        title: "Terminals",
        app_image: mainlogo,
      },
      {
        id: 2,
        icon: img2,
        color: "#292929",
        title: "Share Tokens",
        app_image: mainlogo,
      },
      {
        id: 3,
        icon: img3,
        color: "#292929",
        title: "Funds",
        app_image: mainlogo,
      },
      {
        id: 4,
        icon: img4,
        title: "NFT's",
        color: "#292929",
        app_image: mainlogo,
      },
      {
        id: 5,
        icon: img5,
        color: "rgb(80, 199, 173)",
        title: "Bonds",
        app_image: mainlogo,
      },
    ]);

    setmenuselected({
      id: 0,
      icon: Object.keys(firstapp).length > 0 ? firstapp.color_icon : "",
      color: Object.keys(firstapp).length > 0 ? firstapp.color_code_1 : "",
      title: Object.keys(firstapp).length > 0 ? firstapp.terminal_name : "",
      app_image: Object.keys(firstapp).length > 0 ? firstapp.color_logo : "",
    });
  }, [firstapp]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(email, password);
    }
  };

  const handleLogin = (email1, password1) => {
    setisLoading(true);
    const obj = {
      email: email1,
      password: password1,
    };
    Loginservice(obj).then((response) => {
      let result = response.data;
      if (result.status) {
        localStorage.setItem("user", JSON.stringify(result));
        // setisLoading(false)
        // setuseremail(result)
        handlenav();
      } else {
        setisLoading(false);
        toast(result.message, {
          type: "error",
        });
      }
    });
  };

  const handlenav = () => {
    setselectedurl("fund/routing/btcusdt");
    let url = `terminal`;
    localStorage.setItem("CoinDisplay", "BTC/USDT");
    localStorage.setItem("selectedappcode", "fund");
    navigate(url);
    // localStorage.setItem("pairurl", "/select");
    // handleOpenPopup("fund");
    // navigate(`${location.pathname}/routing/btcusdt`)
  };

  const handleOpenPopup = (appcode) => {
    let presult = [];
    let obj = {
      app_code: localStorage.getItem("selectedappcode"),
      quote: Object.keys(coin).length > 0 ? coin.symbol : "USDT",
    };
    GetpairsOfQuote(obj).then((response) => {
      presult = response.data;
      if (presult.status) {
        setallpairs(presult.coins[0].pairs);
        if (Object.keys(presult).length > 0 && appcode !== "") {
          GetAllPairs(appcode).then((response) => {
            let result = response.data;
            if (result.status) {
              setpairsList(result.coins);
              let coindtata = result.coins.filter((x) => {
                return x.symbol === "USDT";
              });
              if (
                coindtata[0].pairs !== undefined &&
                coindtata[0].pairs.length > 0
              ) {
                setcoin(coindtata[0]);
                console.log(location);
                console.log(localStorage.getItem("pairurl"));
                // if(localStorage.getItem('pairurl') === `/select`) {
                let btcusdtpair = presult.coins[0].pairs.filter((x) => {
                  return x.base === "BTC";
                });
                setselectedPair(btcusdtpair[0]);
                let url = `/${localStorage.getItem(
                  "selectedappcode"
                )}/routing/${btcusdtpair[0].base.toLowerCase()}${btcusdtpair[0].quote.toLowerCase()}`;
                localStorage.setItem("pairurl", "/select");
                setisLoading(false);
                setselectedurl(url);
                navigate(url);
                // }
              }
            }
          });
        }
      }
    });
    // let appcode = localStorage.getItem('selectedappcode')
  };

  return (
    <div className="master1">
      {/* <div className='iconMenu'>
            <>
            {iconMenu.map((each) => {
                    return(
                        <div className="each-icon" key={each.icon}>
                            <img className="icon" src={each.icon} key={each.icon} alt='' style={{opacity: menuselected.icon === each.icon? 1 : ''}}
                            onClick={() => setmenuselected(each)} />
                        </div>
                    )
                })
            }
            </>
        </div> */}
      <div className="form">
        <div className="loginForm">
          {isLoading ? (
            <div className="otclogo">
              <img className="otclogoimg" src={menuselected.icon} alt="" />
            </div>
          ) : (
            ""
          )}
          <div className="innerLogin">
            <img
              className="indianOTClogo"
              src={menuselected.app_image}
              alt=""
            />
            <div className="group" id="email" key="email">
              <input
                id="email"
                autoComplete="email"
                type="text"
                name="email"
                placeholder="Email"
                required
                value={email}
                onInput={(e) => setemail(e.target.value)}
              />
              {/* <label id="email">Email</label> */}
            </div>
            <div className="group1" id="password" key="password">
              <input
                id="password"
                autoComplete="new-password"
                type={eye === false ? "password" : "text"}
                name="password"
                required
                placeholder="Password"
                value={password}
                onInput={(e) => setpassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              
            </div>
            <div className="forgetpassword">Forgot Password</div>
            <button
              className="loginbtn"
           
              onClick={() => handleLogin(email, password)}
            >
              <span>Login</span>
            </button>
            <button

              className="loginbtn"
              style={{ background: "#fff",marginTop: "19px" }}
           
            >
          <img src={google}/>
            </button>
            <div className="EmailRegister">Click Here To Register With Email</div>
          </div>
        </div>
        <ToastContainer position="top-right" newestOnTop />
      </div>
{/* 
      <div className="bottomloginbanner">
        <div className="power">POWERED BY</div>
        <img src={air} 
         onClick={()=>window.open("https://aiprowallet.com","_blank")}
        />
      </div> */}
   
    </div>
    ////./
  );
}

export default Loginpage;
